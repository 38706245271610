import React,{ useState,lazy,Suspense} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import SchematicState from './context/Schematic/SchematicState';
import Navbar from './components/Navbar';
import Alert from './components/Alert';
import Footer from './components/Footer';
import Home from './pages/Home';
import Resources from './pages/Resources/Resources';
import Assets from './pages/Assets';
import About from './pages/About';
import NBC from './pages/Resources/NBC';
import SanReq from './pages/Tools/SanReq/SanReq';
import './App.css'

const Tools = lazy(()=> import("./pages/Tools/Tools"))

function App() {
  const [mode, setMode] = useState('dark'); // Whether dark mode is enabled or not
  const [alert,setAlert]= useState(null);

  const showAlert=(message,type)=>{
      setAlert({
        msg:message,
        type:type
      })
      setTimeout(() => {
        setAlert(null);
      },3000);
  }

  const toggleMode = ()=>{
    if (mode==='dark'){
      setMode('light');
      document.body.style.backgroundColor='#eaeef4';
      showAlert("Light mode has been enabled","success");
    }
    else{
      setMode('dark');
      document.body.style.backgroundColor='#14161f';
      showAlert("Dark mode has been enabled","success");
    }
  }
  return (
  <>
  <SchematicState> 
   <BrowserRouter>
    <Navbar title="Schematically.org" aboutText="About" mode={mode} toggleMode={toggleMode}/>
    <Alert alert={alert}/>
    <div className="container my-5">
          <Routes>
            <Route path="/" element={<Home mode={mode}/>}></Route>
            <Route path="/tools" element={<Suspense fallback={<h1 className={`text-${setMode==='light'?'dark':'light'}`}>Loading...</h1>}><Tools mode={mode}/></Suspense>}></Route>
            <Route path="/resources" element={<Resources mode={mode}/>}></Route>
            <Route path="/about" element={<About mode={mode} />}></Route>
            <Route path="/tools/SanReq" element={<SanReq mode={mode}/>}></Route>    
            <Route path="/resources/NBC" element={<NBC mode={mode}/>}></Route>
            <Route path="/assets" element={<Assets mode={mode}/>}></Route>
          </Routes>
    </div>
    <Footer mode={mode}/>
    </BrowserRouter>
    </SchematicState> 
  </>
  );
}

export default App;
