import React,{useState} from 'react'
import NumericInput from '../NumericInput'; // Import the NumericInput component

const Assembly = () => {

  const [inputValue1, setInputValue1] = useState();
  const [inputValue2, setInputValue2] = useState();
  const [inputValue3, setInputValue3] = useState();
  let m = Number(inputValue1); // m is number of males
  let f = Number(inputValue2); // f is number of females

  const Total=()=>{
    let total;
    total=m+f;
    if(isNaN(total)){total="Male+Female";}
    return total;
  };

  return (
    <div>
        <div className='d-flex justify-content-center'>
        <table className='table table-dark table-hover table-bordered w-50 align-middle'>
          <thead className='align-middle'>
            <tr>
              <th scope="col">Sl No.</th>
              <th scope="col">Fixture</th>
              <th scope="col" colSpan="2">Staff Toilets</th>
            </tr>
            <tr>
              <th scope="col" colSpan="2"></th>
              <th scope="col">Males</th>
              <th scope="col">Females</th>
            </tr>
          </thead> 
          <tbody className='table-group-divider'>
            <tr>
              <th scope="row">1</th>
              <td>Toilet suite comprising one WC and one Washbasin (with optimal shower stall if building used for 24h)</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Water Closets</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Ablution Tap</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Urinals</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>Wash Basins</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">6</th>
              <td>Drinking Water fountain</td>
              <td colSpan="2"></td>
            </tr>
            <tr>
              <th scope="row">7</th>
              <td>Cleaner's Sink</td>
              <td colSpan="4"></td>
            </tr>
            <tr>
              <th scope="row">8</th>
              <td>Kitchen Sink</td>
              <td colSpan="4"></td>
            </tr>           
          </tbody>
        </table>
      </div>
      <div className="container input-group mb-3 w-50 ">
            <NumericInput span="Male" value={inputValue1} onChange={setInputValue1}/>
            <NumericInput span="Female" value={inputValue2} onChange={setInputValue2}/>
      </div>
      <div className="container input-group mb-3 w-50 ">
            <span className="input-group-text">Total Number of People</span>
            <input className="form-control" type="text" value={Total()} aria-label="readonly input" readonly/>
      </div>
      <div className="container input-group mb-3 w-50">
            <NumericInput span="Number of Floors in a Building" value={inputValue3} onChange={setInputValue3}/>
      </div>
      <div className='d-flex justify-content-center'>
        <img src="../assests/SanReq/7.png" alt="" />
      </div>
    </div>       
  )
}

export default Assembly;