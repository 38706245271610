import React from 'react'
import { Link } from 'react-router-dom'

export default function Resources(props) {
  return (
<div className="row" data-bs-theme={props.mode}>
  <div className="col-sm-6 mb-3 mb-sm-0">
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">National Building Code of India 2016</h5>
        <p className="card-text">The National Building Code of India (NBC), a comprehensive building Code, is a national instrument providing guidelines
                    for regulating the building construction activities across the country. It serves as a Model Code for adoption by all
                    agencies involved in building construction works be they Public Works Departments, other government construction departments,
                    local bodies or private construction agencies.</p>
        <Link to="/resources/NBC" className="btn btn-primary">Read the Doc</Link>
      </div>
    </div>
  </div>
</div>
  )
}
