import React from 'react'
import { NavLink } from 'react-router-dom'
import { BsFillHeartFill } from "react-icons/bs"

export default function Navbar(props) {
  return (
    <nav className={`navbar sticky-top navbar-expand-lg navbar-${props.mode} bg-${props.mode}`}>
    <div className="container-fluid container-xxl bd-gutter flex-wrap flex-lg-nowrap">
      <NavLink className="navbar-brand mx-2" to="/"><img src={props.mode==='dark' ? "../images/logo 1.png":"../images/logo 2.png"} alt="Logo" width="300" /></NavLink>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse nav" id="navbarSupportedContent">
      <ul className="navbar-nav nav-underline ms-auto mb-2 mb-lg-0 ">
        <li className="nav-item"><NavLink className="nav-link" to="/">Home</NavLink></li>
        <li className="nav-item"><NavLink className="nav-link" to="/tools">Tools</NavLink></li>
        <li className="nav-item"><NavLink className="nav-link" to="/resources">Resources</NavLink></li>
        <li className="nav-item"><NavLink className="nav-link" to="/assets">Assets</NavLink></li>
        <li className="nav-item"><NavLink className="nav-link" to="/about">{props.aboutText}</NavLink></li>
      </ul>
        <ul className="navbar-nav mx-3">
          <div className="d-inline text-bg-primary rounded-pill text-white">
            <li className="nav-item mx-2"> <NavLink className="nav-link active text-white" to="/donate"><BsFillHeartFill/> Donate</NavLink></li>
          </div>
        </ul>
        <div className={`form-check form-switch mx-2 text-${props.mode==='dark'?'light':'dark'}`} >
          <input className="form-check-input" onClick={props.toggleMode} type="checkbox" role="switch" id="flexSwitchCheckChecked"defaultChecked/>
          <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Darkmode</label>
        </div>
      </div>
    </div>
  </nav>
  )
}
