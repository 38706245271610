import React from 'react'
import { NavLink } from 'react-router-dom'
import {FaStream} from 'react-icons/fa'

export default function NBC(props) {
  return (
    <div className="main-container d-flex">
      <div className='sidebar' id='side_nav'>
        <div className='header-box px-2 pt-3 pb-4'>
          <h1 className="fs-4"><span className={`text-${props.mode === 'light' ? 'dark' : 'light'}`}>Contents</span></h1>
          <button className='btn d-md-none d-block close-btn px-1 py-0 text-white'><FaStream/></button>
        </div>
        <ul className={`text-${props.mode === 'light' ? 'dark' : 'light'} list-unstyled px-2`} >
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>Introduction</strong></NavLink></li>
            <li className=''><NavLink className="nav-link my-3 text" to="/">Availability of the code <br />in groups for Users</NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 0</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 1</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 2</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 3</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 4</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 5</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 6</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 7</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 8</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 9</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 10</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 11</strong></NavLink></li>
          </ul>
          <ul className='list-unstyled'>
            <li className=''><NavLink className="nav-link my-3 text" to="/"><strong>PART 12</strong></NavLink></li>
          </ul>
        </ul>
      </div>
      <div className={`text-${props.mode === 'light' ? 'dark' : 'light'} main mx-4`}>
        <h1 className={`text-${props.mode === 'light' ? 'dark' : 'light'} d-inline-flex px-2 pt-3 pb-4 `}>National Building Code of India (2016)</h1>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Velit eius accusantium nobis commodi consectetur perferendis ut sit quisquam blanditiis corrupti sed saepe cumque officiis, maiores ullam nemo libero voluptatibus quibusdam!</p>
      </div>
      <div className='sidebar2' id='side_nav2'>
        <div className='header-box px-2 pt-3 pb-4'>
          <h1 className="fs-6"><span className={`text-${props.mode === 'light' ? 'dark' : 'light'}`}>On This Page</span></h1>
        </div>
      </div>
  </div>

  )
}
