import React,{useState} from 'react'
import NumericInput from '../NumericInput'; // Import the NumericInput component

const HospitalStaff = () => {

  const [inputValue1, setInputValue1] = useState(0);
  const [inputValue2, setInputValue2] = useState(0);
  const [inputValue3, setInputValue3] = useState(0);
  const [inputValue4, setInputValue4] = useState(0)
  let staffMale = Number(inputValue1); // m is number of males
  let staffFemale = Number(inputValue2); // f is number of females
  let totalStaff = staffMale+staffFemale;
  let nurseMale = Number(inputValue3);
  let nurseFemale=Number(inputValue4);
  let totalNurse=nurseMale + nurseFemale;

  const StaffMaleWcCount=()=>{
    let staffMaleWcCount= staffMale/4;
    return Math.round(staffMaleWcCount)
  };
  const StaffFemaleWcCount=()=>{
    let staffFemaleWcCount= staffFemale/4;
    return Math.round(staffFemaleWcCount)
  };
  const NurseMaleWcCount=()=>{
    let nurseMaleWcCount=0;
    if(nurseMale>35){nurseMaleWcCount=(nurseMale*2)/35}
    else if (nurseMale<=35 & nurseMale>4){return 2}
    else if (nurseMale>0){return 1}
    return Math.round(nurseMaleWcCount)
  };
  const NurseFemaleWcCount=()=>{
    let nurseFemaleWcCount=0;
    if (nurseFemale>25){nurseFemaleWcCount=(nurseFemale*2)/25}
    else if (nurseFemale<=25 & nurseFemale>4){return 2}
    else if (nurseFemale>0){return 1}
    return Math.round(nurseFemaleWcCount)
  };
  return (
    <div>
        <div className='d-flex justify-content-center'>
        <table className='table table-dark table-hover table-bordered w-50 align-middle'>
          <thead className='align-middle'>
            <tr>
              <th scope="col">Sl No.</th>
              <th scope="col">Fixture</th>
              <th scope="col" colSpan="2">Staff Quaters</th>
              <th scope="col" colSpan="2">Nurses Homes</th>
            </tr>
            <tr>
              <th scope="col" colSpan="2"></th>
              <th scope="col">Male</th>
              <th scope="col">Female</th>
              <th scope="col">Male</th>
              <th scope="col">Female</th>
            </tr>
          </thead> 
          <tbody className='table-group-divider'>
            <tr>
              <th scope="row">1</th>
              <td>Water Closets</td>
              <td>{StaffMaleWcCount()}</td>
              <td>{StaffFemaleWcCount()}</td>
              <td>{NurseMaleWcCount()}</td>
              <td>{NurseFemaleWcCount()}</td>
            </tr>
            <tr>
              <th scope="row">2</th>
              <td>Ablution Tap</td>
              <td colSpan={4}>One in each water closet</td>
            </tr>
            <tr>
              <th scope="row">3</th>
              <td>Wash Basins</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">4</th>
              <td>Bath/Showers</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th scope="row">5</th>
              <td>Drinking Water fountain</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
            <th scope="row">6</th>
              <td>Janitor/Cleaner's Sink</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="container input-group mb-3 w-75 ">
            <NumericInput span="Staff (Male)" value={inputValue1} onChange={setInputValue1}/>
            <NumericInput span="Staff (Female)" value={inputValue2} onChange={setInputValue2}/>
        </div>
        <div className="container input-group mb-3 w-75">
            <span className="input-group-text">Total Number of Staff</span>
            <input className="form-control" type="text" value={totalStaff} aria-label="readonly input" readonly/>
        </div>
        <div className="container input-group mb-3 w-75 ">
            <NumericInput span="Nurse (Male)" value={inputValue3} onChange={setInputValue3}/>
            <NumericInput span="Nurse (Female)" value={inputValue4} onChange={setInputValue4}/>
        </div>
        <div className="container input-group mb-3 w-75">
            <span className="input-group-text">Total Number of Nurse</span>
            <input className="form-control" type="text" value={totalNurse} aria-label="readonly input" readonly/>
        </div>
      <div className='d-flex justify-content-center'>
        <img src="../assests/SanReq/8.png" alt="" />
      </div>      
    </div>
  )
}

export default HospitalStaff;
