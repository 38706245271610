import React from 'react'
import {BsFillCheckCircleFill} from 'react-icons/bs'

export default function Alert(props) {
  return (
    <div className='d-flex'>
        {props.alert && <div className="toast-container position-fixed bottom-0 start-50 translate-middle-x border-0 my-5" role="alert" aria-live="assertive" aria-atomic="true">
          <div className={`d-flex align-items-center p-3 rounded-pill text-bg-${props.alert.type}`}>
                <BsFillCheckCircleFill className='me-2'/> {props.alert.msg}
          </div>
        </div>}
    </div>
  )
}
