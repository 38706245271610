import React from "react";
import SchematicContext from "./SchematicContext";

const SchematicState = (props)=>{
    const state={

    }
    return(
        <SchematicContext.Provider value={state}>
            {props.children}
        </SchematicContext.Provider>
    )
}

export default SchematicState;