import React from 'react'
import { NavLink } from 'react-router-dom'

export default function Footer(props) {
  return (
    <footer className={`bd-footer bg-${props.mode==='dark'?'dark':'light'} pt-5 pb-4 mt-auto`}>
      <div className='container text-center text-md-left'>
        <div className="row text-center text-md-left">
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
          <NavLink to="/"><img src={props.mode==='dark' ? "../images/logo_footer.png":"../images/logo 2.png"} width="300" alt="Logo"/></NavLink>
          </div>
          <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mt-3'>
            <h6 className={`text-uppercase mb-4 font-weight-bold text-${props.mode==='light'?'dark':'light'}`}>Tools</h6>
            <NavLink className="nav-link my-3 text-secondary" to="/">Providers1</NavLink>
            <a className="nav-link my-3 text-secondary" href="/">Providers2</a>
            <a className="nav-link my-3 text-secondary" href="/">Providers3</a>
            <a className="nav-link my-3 text-secondary" href="/">Providers4</a>
          </div>
          <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mt-3'>
            <h6 className={`text-uppercase mb-4 font-weight-bold text-${props.mode==='light'?'dark':'light'}`}>Assets</h6>
            <a className="nav-link my-3 text-secondary" href="/">Asset 1</a>
            <a className="nav-link my-3 text-secondary" href="/">Asset 2</a>
            <a className="nav-link my-3 text-secondary" href="/">Asset 3</a>
            <a className="nav-link my-3 text-secondary" href="/">Asset 4</a>
          </div>
          <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mt-3'>
            <h6 className={`text-uppercase mb-4 font-weight-bold text-${props.mode==='light'?'dark':'light'}`}>Resources</h6>
            <NavLink className="nav-link my-3 text-secondary" to="/resources/NBC">NBC</NavLink>
            <a className="nav-link my-3 text-secondary" href="/">Asset 2</a>
            <a className="nav-link my-3 text-secondary" href="/">Asset 3</a>
            <a className="nav-link my-3 text-secondary" href="/">Asset 4</a>
          </div>
          <div className='col-md-2 col-lg-2 col-xl-2 mx-auto mt-3'>
            <h6 className={`nav-item text-uppercase mb-4 font-weight-bold text-${props.mode==='light'?'dark':'light'}`}>Info</h6>
            <NavLink className="nav-link my-3 text-secondary" to="/about">About</NavLink>
            <a className="nav-link my-3 text-secondary" href="/">Contact Us</a>
          </div>
        </div>
      </div>
   </footer>
  )
}